<template>
  <Button :label="_t('label_Ad-hoc_Upload')" class="p-button-success" @click="uploadDialog=true"/>
  <Dialog v-model:visible="uploadDialog" style="min-width: 500px">
    <template #header>
      <h3>{{ _t('label_Ad-hoc_save_attachment') }}</h3>
    </template>
    <p>{{ _t('label_Select_folder') }}</p>
    <div class="p-mb-4">
      <OrganizationLink v-model="selectedOrg" :editMode="true" :required-field="true"/>
    </div>
    <FileUpload :auto="true"
                :choose-label="_t('label_Select_file')"
                :customUpload="true"
                :fileLimit="5"
                :maxFileSize="200*1024*1024*1024"
                :multiple="true" :showCancelButton="false"
                :showUploadButton="false" @beforeSend="onUpload"
                @beforeUpload="onUpload" @error="onUpload"
                @progress="onProgress" @upload="onUpload"
                @uploader="onUploadFiles"
    >
      <template #empty>
        <p>{{ _t('label_Drag_and_drop') }}</p>
      </template>
    </FileUpload>
    <div class="p-pt-2" style="width: 100%; text-align: right">
      <Button :label="_t('label_Select_attachment')" class="p-button-success" @click="uploadDialog = false"/>
    </div>
  </Dialog>
</template>

<script>
import {mapActions} from "vuex";
import OrganizationLink from "./ixarma/OrganizationLink";

export default {
  components: {OrganizationLink},
  name: 'upload-file',
  emits: ['uploaded'],
  data: () => ({
    uploadDialog: false,
    selectedOrg: {
      orgId: localStorage.getItem('orgId'),
      orgName: '',
    },
  }),
  methods: {
    ...mapActions({
      'uploadFile': 'attachments/uploadFile',
      'multiplyUpload': 'attachments/multiplyUpload',
    }),
    onUploadFiles(event) {
      // single
      event.files.map(file => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('orgId', this.selectedOrg.orgId ?? 1);
        this.uploadFile(formData)
            .then(() => this.$emit('uploaded', true));
      });
      // multiple
      // event.files.map(file => {
      //   formData.append('file', file);
      //   // formData.append('files[]', file);
      // });
      // formData.append('orgId', this.getOrganization);
      // this.multiplyUpload(formData);
    },
    onUpload() {
      alert('onUpload');
    },
    onProgress() {
      alert('onProgress');
    },
  }
}
</script>

<style scoped>
::v-deep(.p-fileupload-files .p-button.p-component.p-button-icon-only) {
  display: none;
}

::v-deep(.p-fileupload-files .p-fileupload-filename) {
  width: 100%;
}

::v-deep(.p-fileupload-choose) {
  color: #ffffff;
  background: #689F38;
  border: 1px solid #689F38;
}
</style>
